<template>
  <v-app >
    <Navigation/>
    <v-main class="home">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div class="content" transition="scroll-y-reverse-transition" :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Eventos
            </h1>
          </div>
          <hr class="content__divider"/>

          <div class="service row">
            <v-row>
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2"
                v-for="item in items"
                :key="item.id"
              >
                  <ServiceItem
                    :title="item.title"
                    :description="item.description"
                    :date="item.date"
                    :theme="theme"
                    :img="item.images != undefined ? item.images[0].image.thumb.url : '//via.placeholder.com/300x200'"
                    :service_id="item.id.toString()"
                    button_text="detalles"
                  />
              </div>
            </v-row>
          </div>

        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Logo from '../assets/img/logo.png'

  export default {
    name: 'Locations',
    data () {
      return {
        Logo: Logo,
        theme:{
          primaryColor: '#17A277',
          secundaryColor: '#5bbc9e',
          service: 'events'
        },
        months: ['Enero', 'Febrero', 'Marzo', 'Abril'],
        items: []
      }
    },
    components: {
    },
    mounted(){
      this.checkDevice();
    }
  }
</script>
